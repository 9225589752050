import { CustomerLogos } from "../components/Customers";
import Header from "../components/Header";
import About from "../components/About";
import Quotes from "../components/Quotes";
import { ContactForm } from "../components/ContactForm";
import Footer from "../components/Footer";
import Features from "../components/Features";

export default function LandingPage() {
  return (
    <div
      className="flex flex-col text-left mx-auto md:px-56 sm:px-2 
                    bg-gradient-to-r from-impact-yellow to-impact-red"
    >
      <Header />
      <CustomerLogos />
      <About />
      <Features />
      {/* <ThoughtExample /> */}
      {/* <CtaSection /> */}
      {/* <CustomersCta /> */}
      <Quotes />
      <ContactForm />
      <Footer />
    </div>
  );
}
