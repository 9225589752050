import React, { useState } from "react";
import getImage from "../helpers/getImage";

function Header() {
  const [cubeNumber, setCubeNumber] = useState(1);
  // const [seconds, setSeconds] = useState(0);

  const changeCube = () => {
    const nextCube = cubeNumber < 5 ? cubeNumber + 1 : 1;
    setCubeNumber(nextCube);
  };

  // useEffect(() => {
  //   setInterval(() => changeCube(), 3000)
  //   setInterval(() => setSeconds(seconds + 1), 1000)
  // }, [changeCube])

  return (
    <div>
      <div className="max-w-7xl mx-auto py-4 px-4  sm:px-6 lg:px-8 space-y-4 ">
        <div className="cursor-pointer" onClick={changeCube}>
          <img
            src={getImage(`impact-cube-idea ${cubeNumber}.png`)}
            alt="impactcube"
            placeholder="blurred"
            className="mx-auto w-[500px] object-contain"
          />
        </div>
        <div className="ml-20 lg:ml-40">
          <img
            src={getImage("clickme.png")}
            alt=""
            className="mx-auto h-[40px] object-contain"
          />
        </div>
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl ">
            The Impact Cube.
          </p>
          <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-6xl py-4">
            Think outside the box. With your hands.
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-white">
            The Impact Cube is a customisable idea-generation workshop-tool with
            a purpose. Designed for impact entrepreneurship and divergent
            thinking.
          </p>
          <div className="mx-auto">
            <a href="#contactUs">
              <button className="buttonStyle">Order now!</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
