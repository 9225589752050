import getImage from "../helpers/getImage";

const features = [
  {
    name: "Resources",
    description:
      "Use what your business creates: Energy, Time, Money, Waste, and more.",
    image: "resources.png",
    color: "E4654D",
  },
  {
    name: "Target Groups",
    description:
      "Use your target group, whether people, Organisations, companies or animals.",
    image: "target-groups.png",
    color: "2F9867",
  },
  {
    name: "Values",
    description:
      "These concepts bring your own personal skills into the workshop. Maybe something that you own or have access to, or someone you know can be the key to unlocking your idea?",
    image: "values.png",
    color: "4155B9",
  },
  {
    name: "Business Models",
    description:
      "The way you bring your idea into the world: Products or Services, Blockchain, Sharing things, and more.",
    image: "business-models.png",
    color: "FEB41A",
  },
  {
    name: "Agenda 2030",
    description:
      "All 17 SDGs from UN`s global goals, in their famous icons, split over two sides and grouped according to the cake model.",
    image: "color-wheel.png",
    color: "FFFFFF",
  },
  {
    name: "Dynamic",
    description:
      "Two tiles are holding a logo instead of a word. Think of it as a joker: Now you can bring your own concepts into the workshop and exchange the meaning of the logo instead of ordering a different cube.",
    image: "customisation.png",
    color: "000000",
  },
];

export default function Features() {
  return (
    <div className="relative border-t py-4 lg:py-10">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-impact-blue">
          Features
        </h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Countless combinations
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-white">
          The Impact Cube sparks ideas and conversations by combining terms from
          the following categories while leaving room for customisation.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <img
                        src={getImage(feature.image)}
                        alt={feature.name}
                        className="h-12 w-12 rounded"
                      />
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
