import { FaLinkedin, FaInstagram } from "react-icons/fa"

const navigation = [
  {
    name: 'Instagram',
    href: 'http://instagram.com/impactdesignse',
    icon: FaInstagram
  },
  {
    name: 'LinkedIn',
    href: 'https://linkedin.com/company/impact-design-sweden',
    icon: FaLinkedin
  },
]

export default function Footer() {
  return (
    <footer className=" ">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} target="_blank" className="text-gray-300 hover:text-impact-blue" rel="noreferrer">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-300">&copy; 2020-2022 Nils Alm / Impact Cube. All rights reserved.</p>
        </div>
      </div>

    </footer>
  )
}
