import getImage from "../helpers/getImage";

export default function About() {
  return (
    <div className="py-4">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative py-24 px-8 bg-impact-red rounded-xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
            <img
              src={getImage("event.png")}
              alt="workshop"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="relative lg:col-span-2">
            <blockquote className="mt-6 text-white">
              <p className="text-xl font-medium sm:text-2xl p-1">
                The Impact Cube solves two problems:
              </p>
              <p className="text-xl font-medium sm:text-2xl p-1">
                - It makes it easy to really think outside the box, also known
                as divergent thinking.
              </p>
              <p className="text-xl font-medium sm:text-2xl p-1">
                - It generates many ideas, because if you want good ideas you
                need many ideas, as Linus Pauling said.
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}
