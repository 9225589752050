import React from "react"
import ReactDOM from 'react-dom';
import "./index.css"

import LandingPage from "./pages/LandingPage";

ReactDOM.render(
  <React.StrictMode>
    <LandingPage />
  </React.StrictMode>,
  document.getElementById('root')
);
