import emailjs from '@emailjs/browser';
import { ChangeEvent, Fragment, useState } from 'react';
import { Transition } from '@headlessui/react'
import { Cross1Icon, CheckCircledIcon, CrossCircledIcon } from '@modulz/radix-icons';

export function ContactForm() {
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID!;

  const placeholder = "Hello! \n\nWe're happy to hear from you!\n\nHave a good day ☀️ "

  const [formData, setFormData] = useState(
    {
      name: "",
      email: "",
      interest: "",
      number: 0,
      message: "",
    }
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setFormData({ ...formData, message: value });
  };


  const sendEmail = (e: any) => {
    e.preventDefault();

    if (formData.email === ""
      || formData.message === ""
      || formData.name === ""
      || formData.email.includes('@') === false) {
      setShow(true);
      setSuccess(false)
      return;
    }

    emailjs.send(serviceId, templateId, formData, userId)
      .then((result) => {
        console.log(result.text);
        setShow(true);
        setSuccess(true)
      }, (error) => {
        console.log(error.text);
        setShow(true);
        setSuccess(false)
      });
  };

  const [success, setSuccess] = useState(true)
  const [show, setShow] = useState(false)
  function Popup() {
    const titleSuccess = "You're email was sent successfully!";
    const textSuccess = "We'll get back to you soon."
    const titleFailure = "Something went wrong!"
    const textFailure = "Please check if you have filled in all fields correctly."
    return (
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {success ? <CheckCircledIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        : <CrossCircledIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{success ? titleSuccess : titleFailure}</p>
                      <p className="mt-1 text-sm text-gray-500">{success ? textSuccess : textFailure}</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-impact-blue"
                        onClick={() => {
                          setShow(false)
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <Cross1Icon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    )
  }

  return (
    <div id="contactUs" className="py-10">
      <div className="mt-10 sm:mt-0">
        <div className="px-4 sm:px-0">
          <h3 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Get in touch</h3>
          <p className="m-1 text-lg text-white">Let us know what you're interested in and we'll get back to you!</p>
        </div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 md:mt-0 md:col-span-3">
            <form onSubmit={sendEmail}>
              <div className="shadow overflow-hidden sm:rounded-md bg-gradient-to-r from-impact-blue to-impact-teal">
                <div className="px-4 py-5  sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="name" className="block text-sm font-medium text-white">Your Name or Organisation</label>
                      <input type="text" name="name" id="name" value={formData.name} onChange={handleChange} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="email" className="block text-sm font-medium text-white">Your Email address</label>
                      <input type="text" name="email" id="email" value={formData.email} onChange={handleChange} className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                    </div>

                    {/* <fieldset className="col-span-6 sm:col-span-6">
                      <div>
                        <legend className="text-base font-medium text-white">Give us a hint</legend>
                        <p className="text-sm text-white">I am interested in ...</p>
                      </div>
                      <div className="mt-4 space-y-4">
                        <div className="flex items-center">
                          <input id="interest-classic" name="cube-version" type="radio" className="focus:ring-impact-yellow h-4 w-4 text-impact-yellow border-gray-300" />
                          <label htmlFor="interest-classic" className="ml-3 block text-sm font-medium text-white">The classic Impact Cube.</label>
                        </div>
                        <div className="flex items-center">
                          <input id="interest-custom" name="cube-version" type="radio" className="focus:ring-impact-yellow h-4 w-4 text-impact-yellow border-gray-300" />
                          <label htmlFor="interest-custom" className="ml-3 block text-sm font-medium text-white">A bespoke version for our organisation.</label>
                        </div>
                      </div>
                    </fieldset> */}

                    <div className="col-span-6">
                      <label htmlFor="message" className="block text-sm font-medium text-white">Message</label>
                      <div className="mt-1">
                        <textarea id="message" name="message" rows={6} value={formData.message} onChange={handleTextAreaChange} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" placeholder={placeholder}></textarea>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-impact-blue hover:bg-impact-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-impact-blue ">Send</button>
                </div>
                <Popup />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
