import getImage from "../helpers/getImage";

const customers = [
  {
    name: "Sommarlovsentreprenör Härnösand",
    link: "https://technichus.se/sommarlovsentreprenor/",
    logo: "Sommarlovsentreprenor.png",
  },
  {
    name: "Prototyp Stockholm",
    link: "http://prototyp.se",
    logo: "prototyp.png",
  },
  {
    name: "Ecollective",
    link: "http://weareecollective.com",
    logo: "ecollective.png",
  },
  {
    name: "BizMaker Sundsvall",
    link: "http://bizmaker.se",
    logo: "BizMaker_symbol__black.png",
  },
  {
    name: "Drivhuset Uppsala",
    link: "http://uppsala.drivhuset.se",
    logo: "drivhuset.png",
  },
  {
    name: "Salzburg Research",
    link: "http://salzburgresearch.at",
    logo: "salzburg-research.jpg",
  },
];

export function Customers() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          {customers.map((customer) => {
            return (
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <a href={customer.link} target="_blank" rel="noreferrer">
                  <img
                    src={getImage(customer.logo)}
                    alt={customer.name}
                    placeholder="blurred"
                    width={100}
                    height={100}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export function CustomersCta() {
  return (
    <div className="">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-10 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Used by organisations, that think in new ways.
            </h2>
            <p className="mt-3 max-w-3xl text-lg text-white">
              "The best way to have a good idea is to have a lot of ideas." -
              Linus Pauling
            </p>
            <div className="mt-8 sm:flex">
              <a href="#contactUs" className="buttonStyle">
                Join!
              </a>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-2 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            {customers.map((customer, index) => {
              return (
                <div
                  className="col-span-1 flex justify-center py-8 px-8 border border-impact-yellow rounded"
                  key={index}
                >
                  <a href={customer.link} target="_blank" rel="noreferrer">
                    <img
                      src={getImage(customer.logo)}
                      alt={customer.name}
                      placeholder="blurred"
                      className="h-20 object-contain "
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function CustomerLogos() {
  return (
    <div className="">
      <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6">
          {customers.map((customer, index) => {
            return (
              <div
                className="group col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
                key={index}
              >
                <img
                  src={getImage(customer.logo)}
                  alt={customer.name}
                  data-tooltip-target="tooltip"
                  data-tooltip-placement="bottom"
                  placeholder="blurred"
                  className="h-14 object-contain grayscale hover:grayscale-0"
                />
                {/* <p className="opacity-0 group-hover:opacity-100">{customer.name}</p> */}
                <div
                  id="tooltip"
                  role="tooltip"
                  className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 shadow-sm opacity-0 tooltip"
                >
                  {customer.name}
                  <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
